body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.models_engate {
  background-color: white;
  width: 420px;
  overflow-y: auto;
  height: 0px;
}

.models_engate_scroll {
  background-color: white;
  width: 420px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  height: 150px;
}

.card_model_engate {
  display: flex;
  cursor: pointer;
  margin-top: 0.9rem;
  align-items: center;
}

.card_model_engate_not_margin {
  display: flex;
  cursor: pointer;
  margin-top: -1rem;
  align-items: center;
}

.card_model_engate_not_margin>img {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.card_model_engate_not_margin>p {
  width: 250px;
  font-family: Poppins-Medium;
  color: black;
  font-size: 0.9rem;
}

.card_model_engate>img {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.card_model_engate>p {
  width: 250px;
  font-family: Poppins-Medium;
  color: black;
  font-size: 0.9rem;
}

/* LANDING */

.landing {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* Centraliza horizontalmente */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* Centraliza verticalmente */
  height: 100vh;
  /* Certifica-se de que o contêiner ocupa a altura total da viewport */
}

.landing>.colum_primary>img {
  width: 640px;
  height: 100vh;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  object-fit: cover;
}

.landing>.colum_secondary>p {
  font-family: Poppins-Medium;
  color: black;
  font-size: 1.8rem;
}

.landing>.colum_secondary>button {
  background-color: black;
  width: 420px;
  height: 48px;
  margin-top: 3rem;
  font-family: Poppins-Medium;
  color: white;
  font-size: 1rem;
  border-radius: 60px;
}

.landing>.colum_secondary>img {

  width: 190px;
  border-radius: 5px;
  margin-bottom: 0rem;
  -o-object-fit: contain;
  object-fit: contain;
  height: 130px;

}

.open_icon_input {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 50%;
}

.close_icon_input {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 50%;
}

/*  */



/* DASHBOARD */

.dashboard {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 239px 1fr;
  grid-template-columns: 239px 1fr;
  height: 100%;
}

/* CONTAINER DATA */

.container_data {
  width: 1090px;
  margin: 2rem auto;
}

/* CONTAINER STARTPN TABLE */


.container_startpn_table {
  width: 100%;
  margin-top: 1.1rem;

}

.container_startpn_table table {
  width: 100%;
  table-layout: fixed;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0px 20px;

}

.container_startpn_table thead {
  color: rgb(106, 106, 106);
  font-size: 0.9rem;
  font-family: Poppins-Medium;
  height: 68px;
}


td>p {
  overflow: hidden;
  white-space: nowrap;
  margin: 0.9rem;
  text-overflow: clip;
  text-overflow: ellipsis;
}

.container_startpn_table thead>tr>td {
  padding-left: 2rem;
  padding-right: 2rem;


  border-top: 1px solid #D7D7D7;
  border-bottom: 1px solid #D7D7D7;
}

.container_startpn_table thead>tr>td:last-child {
  border-start-end-radius: 10px;
  border-end-end-radius: 10px;
  border-right: 1px solid #D7D7D7;
}

.container_startpn_table thead>tr>td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: 1px solid #D7D7D7;
}

.container_startpn_table tbody>tr>td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: 1px solid #D7D7D7;
}

.container_startpn_table tbody>tr>td:last-child {
  border-top-right-radius: 10px;
  border-end-end-radius: 10px;
  border-right: 1px solid #D7D7D7;
}

.container_startpn_table tbody>tr td {
  cursor: pointer;
  padding: 1rem;
  /* Espaçamento interno das células */
}

.container_startpn_table .special_td_image {
  position: relative;
}

.container_startpn_table tbody>tr>td>img {
  top: 15px;
  left: 20px;
  width: 45px;
  height: 45px;
  border-radius: 100px;
}

.container_startpn_table tbody>tr>td {
  padding-left: 0rem;
  padding-right: 0rem;
  color: black;
  font-size: 0.9rem;
  font-family: "Poppins-Medium";

  border-top: 1px solid #D7D7D7;
  border-bottom: 1px solid #D7D7D7;
}

/* */

.textPrimaryHeaderDashboard {
  font-family: Poppins-SemiBold;
  font-size: 1.87rem;
  color: #373435;
}



.sidebar {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 239px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
  background-color: white;
  border-right: 1px solid #D7D7D7;
  height: 100vh;
}

.sidebar>div {
  margin-bottom: 1.2rem;
}


.sidebar>img {
  width: 270px;
  margin-bottom: 1.7rem;
}

.exit_icon {
  position: absolute;
  bottom: 35px;
  width: 70px;
  height: 70px;
}


/* SIDEBAR CHECKED */

.checked {
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(0, 0, 0, 0.774);
  width: 205px;
  height: 39px;

  padding: 1.3rem;
  padding-left: 13px;
  border-radius: 8px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.checked>p {
  margin-left: 1rem;
  position: relative;
  top: -3px;
  font-size: 1.0rem;
  font-family: Poppins-Medium;
  color: white;
  height: 0px;
}

.not_checked {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: white;
  width: 205px;
  height: 39px;
  padding: 1.3rem;
  padding-left: 13px;
  border-radius: 8px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.not_checked>p {
  margin-left: 1rem;
  position: relative;
  top: -3px;
  font-size: 1.0rem;
  font-family: Poppins-Medium;
  color: white;
  height: 0px;
}

/* SIDEBAR NOT CHECKED */

.not_checked {
  cursor: pointer;

}

.not_checked>p {
  margin-left: 1rem;
  position: relative;
  top: -3px;
  font-size: 1.0rem;
  font-family: Poppins-Medium;
  color: #787486;
  height: 0px;
}

.company_sidebar {
  width: 40px;
  -o-object-fit: contain;
  object-fit: contain;
  margin-top: 3.9rem;
  height: 50px;
}

.filter {
  display: flex;
  margin-top: 1.3rem;
  justify-content: space-between;
}


/* INPUT SEARCH*/

.container_input_search {
  position: relative;
  width: 290px;
  height: 45px;
}

.container_input_search>img {
  position: absolute;
  top: 12px;
  left: 15px;
}

.container_input_search>input {
  width: 290px;
  outline: none;
  text-indent: 50px;
  height: 45px;
  font-family: Poppins-Regular;
  color: #7D7D7D;
  font-size: 1rem;

  border-radius: 60px;
  border: 1px solid #D7D7D7;
}


.filter>button {
  border-radius: 50px;
  width: 142px;
  height: 43px;
  border: none;

  font-family: Poppins-Medium;
  color: white;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.774);
}


.textClientInfoHeader {
  margin-top: 15px;
  font-size: 0.9rem;
  font-family: Poppins-Regular;
  position: relative;
  right: 12px;
}


/* MODAL */


.container_modal_options>button {


  width: 100%;
  height: 65px;
  border-top: 1px solid #D7D7D7;

  border-bottom: 1px solid #D7D7D7;
  background-color: white;
}

.container_header_modal_user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}


.container_modal_options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}


.btn_primary_modal_option {
  font-family: Poppins-Medium;
  border: none;
  border-top: 1px solid #D7D7D7;
  color: black;
  font-size: 18px;
}

.container_select {
  display: flex;
  flex-direction: column;
}


.container_select>select {
  position: relative;
  width: 420px;
  height: 50px;
  font-family: Poppins-Medium;
  font-size: 1rem;
  padding-left: 0.9rem;
  color: black;
  outline: none;
  margin-bottom: 0.9rem;
  border-radius: 10px;
  border: 1px solid #D7D7D7
}

.container_select>.textPrimaryLabelInput {
  margin-bottom: 14.43px;

}

.btn_secondary_modal_option {
  border: none;
  font-family: Poppins-Medium;
  color: #EA0000;
  font-size: 18px;
  margin-bottom: 40px;
}

.btn_secondary_modal_option_print {
  border: none;
  font-family: Poppins-Medium;
  color: black;
  font-size: 18px;
  margin-bottom:20px;
}


.container_header_modal_user>button {
  width: 105px;
  border: none;
  height: 39px;
  border-radius: 50px;
  font-family: Poppins-Medium;
  color: white;
  background-color: rgba(0, 0, 0, 0.774);
  ;
}


.container_header_modal_user>div {
  width: 175px;
  height: 30px;
}

.container_header_modal_user>p {
  color: #000000;
  height: 15px;
  font-family: Poppins-Medium;
  font-size: 1.125rem;

}

.container_header_modal_user>div p {
  color: #000000;
  height: 15px;
  font-family: Poppins-Medium;
  font-size: 1.125rem;

}

/* END MODAL */


.textPrimaryModalFile {
  margin-top: 0.9rem;
  font-family: Poppins-Medium;
  font-size: 1.125rem;
  color: black;

}

.textSecondaryModalFile {
  cursor: pointer;
  margin-top: 0.2rem;
  font-family: Poppins-Medium;
  font-size: 1rem;
  color: black;
}

.textPrimaryLabelInput {
  cursor: pointer;
  font-family: Poppins-Medium;
  font-size: 1rem;
  color: black;
}

.flex_modal_options {
  display: flex;
  align-items: center;
}

.flex_modal_options>button {
  margin: 0.9rem;
  color: black;
  background-color: white;
}

.container_input_kit {
  width: 420px;
  position: relative;
}

.container_input_kit>p {
  cursor: pointer;
  top: 0;
  font-family: Poppins-Medium;
  right: 0;
  position: absolute;
}

.container_kits_scroll {
  height: 220px;
  overflow-y: auto;
}

.container_input {
  display: flex;
  width: 420px;
  position: relative;
  flex-direction: column;
}

.icon_mobile{
  display: none;
}
tr > td > input[type="checkbox"]{
  position: relative;
  top: 5px;
  margin-right: 0.9rem;
  width: 20px;
  height: 20px;

}
@media screen and (max-width:1097px) {
 
  .check_box_mobile{
    display: none;
  }
  
  .icon_mobile{
    display: block;
  }
  
.models_engate {
  background-color: white;
  width: 90%;
  overflow-y: auto;
  height: 0px;
}

.models_engate_scroll {
  background-color: white;
  width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  height: 150px;
}
.card_model_engate>img {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.card_model_engate>p {
  width: 150px;
  font-family: Poppins-Medium;
  color: black;
  font-size: 0.9rem;
}
  .container_input_kit {
    width: 100%;
    position: relative;
  }
  
  .container_input_kit>p {
    cursor: pointer;
    top: 0;
    font-family: Poppins-Medium;
    right: 0;
    position: absolute;
  }



  td>p {
    width: 90px;
    overflow: hidden;
    white-space: nowrap;
    margin: 0.9rem;
    text-overflow: clip;
    text-overflow: ellipsis;
  }

  tr > td > input[type="checkbox"]{
    margin-right: 0.9rem;
    width: 14px;
    top: 2px;

    height: 14px;
  }

  .container_startpn_table {
    width: 100%;
    overflow-x: scroll;
    margin-top: 1.1rem;

  }

  tr > td > svg{
    width: 80px;
  }

  .container_startpn_table table {
    width:100%;
    text-align: center;
    border-collapse: separate;
    border-spacing: 0px 20px;

  }

  .container_startpn_table thead {
    color: rgb(106, 106, 106);
    font-size: 0.9rem;
    font-family: Poppins-Medium;
    height: 68px;
  }

  /* CONTAINER DATA */

  .container_data {
    width: 90%;
    margin: 2rem auto;
  }

  .dropdown {
    display: none;
  }

  .dashboard {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 0px 1fr;
    grid-template-columns: 0px 1fr;
    height: 100%;
  }

  .container_data_file{
    display: flex;

    margin-top: 0.9rem;
  }

  .container_data_file > p {
    overflow: hidden;
    width: 140px;
    margin-left: 0.4rem;
    white-space: nowrap;
    text-overflow: clip;
    text-overflow: ellipsis;
  }


  /* INPUT SEARCH*/

  .container_input_search {
    position: relative;
    width: 90%;
    height: 45px;
  }

  .container_input_search>img {
    position: absolute;
    top: 12px;
    left: 15px;
  }

  .container_input_search>input {
    width: 100%;
    outline: none;
    text-indent: 50px;
    height: 45px;
    font-family: Poppins-Regular;
    color: #7D7D7D;
    font-size: 1rem;

    border-radius: 60px;
    border: 1px solid #D7D7D7;
  }

  .filter {
    display: flex;
    margin-top: 1.3rem;
    justify-content: space-between;
  }

  .preview_image{
    width: 220px;
    border-radius: 5px;
    height: 220px;
    object-fit: contain;
  }


  .filter>button {
    border-radius: 50px;
    width: 50%;
    margin-left: 0.9rem;
    height: 43px;
    border: none;
    
    font-family: Poppins-Medium;
    color: white;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.774);
  }


  .sidebar {
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 239px;
    left: -999px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    background-color: white;
    border-right: 1px solid #D7D7D7;
    height: 100vh;
  }
  .sidebar_open{
    z-index: 999999;
    transition: all .2s ;
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 239px;
    left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    background-color: white;
    border-right: 1px solid #D7D7D7;
    height: 100%;
  }

  .company_sidebar {
    width: 160px;
    -o-object-fit: contain;
    object-fit: contain;
    margin-top: 3.9rem;
    margin-bottom: 1.4rem;
    height: 120px;
  }
  

/* SIDEBAR CHECKED */

.checked {
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(0, 0, 0, 0.774);
  width: 205px;
  height: 39px;
  margin-bottom: 1rem;

  padding: 1.3rem;
  padding-left: 13px;
  border-radius: 8px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.checked>p {
  margin-left: 1rem;
  position: relative;
  top: -3px;
  font-size: 1.0rem;
  font-family: Poppins-Medium;
  color: white;
  height: 0px;
}

.not_checked {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  margin-bottom: 0.9rem;

  display: flex;
  background-color: white;
  width: 205px;
  height: 39px;
  padding: 1.3rem;
  padding-left: 13px;
  border-radius: 8px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.not_checked>p {
  margin-left: 1rem;
  position: relative;
  top: -3px;
  font-size: 1.0rem;
  font-family: Poppins-Medium;
  color: white;
  height: 0px;
}

/* SIDEBAR NOT CHECKED */

.not_checked {
  cursor: pointer;

}

.not_checked>p {
  margin-left: 1rem;
  position: relative;
  top: -3px;
  font-size: 1.0rem;
  font-family: Poppins-Medium;
  color: #787486;
  height: 0px;
}

  .container_input {
    display: flex;
    width: 90%;
    margin: 0 auto;
    position: relative;
    flex-direction: column;
  }



  .landing>.colum_secondary {
    display: flex;
    
    flex-direction: column;
    align-items: center;
    flex-direction: column;
  }


  .colum_primary {
    display: none;
  }

  .landing>.colum_secondary>img {

    width: 190px;
    border-radius: 5px;
    margin-bottom: 0rem;
    -o-object-fit: contain;
    object-fit: contain;
    height: 140px;
  
  }
  .landing {
    display: grid;
    width: 100%;
    margin-top: 1rem;
    -ms-grid-columns: 1fr 0fr;
    grid-template-columns: 1fr 0fr;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    /* Centraliza horizontalmente */
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    /* Centraliza verticalmente */

    /* Certifica-se de que o contêiner ocupa a altura total da viewport */
  }

  .landing>.colum_secondary>button {
    background-color: black;
    width: 90%;
    height: 48px;
    font-family: Poppins-Medium;
    color: white;
    font-size: 1rem;
    border-radius: 60px;
  }

  .open_icon_input {
    cursor: pointer;
    position: absolute;
    right: 9px;
    top: 43%;
  }

  .close_icon_input {
    cursor: pointer;
    position: absolute;
    right: 9px;
    top: 43%;
  }
}