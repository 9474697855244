@font-face {
  font-family: Poppins-Regular;
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins-Medium;
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}


.input_style_landing {
  position: relative;
  width: 420px;

  height: 50px;
  font-family: Poppins-Medium;
  font-size: 1rem;
  padding-left: 0.9rem;
  color: black;
  outline: none;
  margin-bottom: 0.9rem;
  border-radius: 10px;
  border: 1px solid #D7D7D7
}


.input_style_landing::placeholder{
  text-transform: lowercase;
}

.textPrimaryLabelInput {
  font-family: Poppins-Medium;
  color: black;
  font-weight: 400;
  font-size: 0.875rem;

}

@media screen and (max-width:1097px) {

  .container_input {
    display: flex;
    margin: 0 auto;
    width: 95%;
    flex-direction: column;
  }

  .input_style_landing {
    position: relative;
    width: 100%;
    height: 50px;
    font-family: Poppins-Medium;
    font-size: 1rem;
    padding-left: 0.9rem;
    color: black;
    outline: none;
    margin-bottom: 1.9rem;
    border-radius: 10px;
    border: 1px solid #D7D7D7
  }


}